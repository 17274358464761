import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilePlus, Search } from 'tabler-icons-react';
import {
  OrderDetailModal,
  OrderEditModal,
  OrderCreateModal,
  Th,
  PatientSelectModal,
  OrderEquipmentModal,
  DeliveryEquipmentModal,
  InfiniteScrollArea,
} from '../../components';
import { useOrders, usePageStore } from '../../hooks';
import { IFilteredPatient, ILocationState, IOrder } from '../../models';
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

function filterData(data: IOrder[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: IOrder[],
  payload: { sortBy: keyof IOrder; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const OnDemandOrdersSubPage = (props: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const state = useLocation().state as ILocationState;

  const filterValues = usePageStore((state) => state.onDemandOrdersFilterValues);
  const setFilterValues = usePageStore((state) => state.setOnDemandOrdersFilterValues);

  const [selectedPatient, setSelectedPatient] = useState<IFilteredPatient | undefined>(
    state ? state.filteredPatient : undefined
  );
  const [patientSelected, setPatientSelected] = useState<boolean>(false);

  const defaultOrder = {
    orderId: '',
    patientId: '',
    patientFirstName: '',
    patientLastName: '',
    patientFullName: '',
    patientFullNameFormatted: '',
    patientPhoneNumber: '',
    patientAddress: '',
    patientCity: '',
    patientNotes: '',
    isAMDelivery: false,
    deliveryTimeName: '',
    requestedDeliveryDate: new Date(),
    liquid: false,
    notes: '',
    isUrgent: false,
    standingOrderId: undefined,
    standingOrderReschedule: false,
    deliveryReschedule: false,
    fulfilled: false,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  };

  const [detailOrder, setDetailOrder] = useState<IOrder>(
    state ? (state.order ? state.order : defaultOrder) : defaultOrder
  );
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [orderDeleted, setOrderDeleted] = useState<boolean>(false);
  const [orderUpdateError, setOrderUpdateError] = useState<boolean>(false);
  const [patientSelectModalOpen, setPatientSelectModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(selectedPatient !== undefined);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState<boolean>(false);
  const [deliveryEquipmentModalOpen, setDeliveryEquipmentModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(detailOrder.orderId !== '');

  const defaultKeys: string[] = [
    'patientFirstName',
    'patientLastName',
    'patientFullName',
    'patientFullNameFormatted',
    'deliveryTimeName',
    'patientAddress',
    'patientCity',
    'requestedDeliveryDate',
    'liquid',
    'standingOrderReschedule',
    'fulfilled',
  ];

  let orders = useOrders();

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(orders.data);
  const [sortBy, setSortBy] = useState<keyof IOrder>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof IOrder) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(orders.data!, { sortBy: field, reversed, search }, defaultKeys));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(orders.data!, { sortBy, reversed: reverseSortDirection, search: value }, defaultKeys)
    );
  };

  const rows = sortedData?.map((row) => {
    let newRow = (
      <tr
        key={row.orderId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDetailOrder(row);
          setDetailModalOpen(true);
        }}
      >
        <td
          style={{ width: 120, wordWrap: 'break-word' }}
        >{`${row.patientLastName}, ${row.patientFirstName}`}</td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          {dayjs(row.requestedDeliveryDate).format('YYYY-MM-DD')}
        </td>
        <td style={{ width: 90, wordWrap: 'break-word' }}>{`${row.deliveryTimeName}`}</td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>{`${row.patientAddress}`}</td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>{`${row.patientCity}`}</td>
        <td style={{ width: 80, wordWrap: 'break-word' }}>
          <Checkbox checked={row.liquid} readOnly />
        </td>
        <td style={{ width: 80, wordWrap: 'break-word' }}>
          <Checkbox checked={row.isUrgent} readOnly />
        </td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          <Checkbox checked={row.standingOrderReschedule} readOnly />
        </td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          <Checkbox checked={row.fulfilled} readOnly />
        </td>
      </tr>
    );

    let test = 0;
    let target = filterValues.length;

    if (filterValues.find((a) => a === 'am') && filterValues.find((a) => a === 'pm')) {
      target--;
    }
    if (
      filterValues.find((a) => a === 'fulfilled') &&
      filterValues.find((a) => a === 'unfulfilled')
    ) {
      target--;
    }

    if (filterValues.find((a) => a === 'am')) {
      if (row.isAMDelivery) {
        test++;
      }
    }
    if (filterValues.find((a) => a === 'pm')) {
      if (!row.isAMDelivery) {
        test++;
      }
    }
    if (filterValues.find((a) => a === 'fulfilled')) {
      if (row.fulfilled) {
        test++;
      }
    }
    if (filterValues.find((a) => a === 'unfulfilled')) {
      if (!row.fulfilled) {
        test++;
      }
    }
    if (filterValues.find((a) => a === 'rescheduled')) {
      if (row.standingOrderReschedule) {
        test++;
      }
    }
    if (test === target) {
      return newRow;
    }
  });

  useEffect(() => {
    if (!orders.data) {
      setSortedData(orders.data);
    } else {
      setSortedData(
        sortData(orders.data, { sortBy, reversed: reverseSortDirection, search }, defaultKeys)
      );
    }
  }, [orders.data, reverseSortDirection, search, sortBy]);

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ height: '100%' }}>
        <Center mb="sm">
          <Group
            position="apart"
            p="sm"
            className={classes.card}
            mb={`calc(${theme.spacing.md} - 1)`}
          >
            <Text style={{ width: 'fit-content' }} ml="sm">
              Filters:
            </Text>
            <Center>
              <Chip.Group multiple value={filterValues} onChange={setFilterValues}>
                <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                  <Chip variant="filled" value="unfulfilled">
                    Unfulfilled
                  </Chip>
                  <Chip variant="filled" value="fulfilled">
                    Fulfilled
                  </Chip>
                  <Chip variant="filled" value="am">
                    AM
                  </Chip>
                  <Chip variant="filled" value="pm">
                    PM
                  </Chip>
                  <Chip variant="filled" value="rescheduled">
                    Rescheduled Standing Orders
                  </Chip>
                </Group>
              </Chip.Group>
            </Center>
          </Group>
        </Center>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <ScrollArea type="hover" style={{ width: '100%' }}>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <Th
                  sorted={sortBy === 'patientLastName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientLastName')}
                  style={{ width: 120 }}
                >
                  Patient Name
                </Th>
                <Th
                  sorted={sortBy === 'requestedDeliveryDate'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('requestedDeliveryDate')}
                  style={{ width: 100 }}
                >
                  Requested Delivery Date
                </Th>
                <Th
                  sorted={sortBy === 'deliveryTimeName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('deliveryTimeName')}
                  style={{ width: 90 }}
                >
                  Delivery Time
                </Th>
                <Th
                  sorted={sortBy === 'patientAddress'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientAddress')}
                  style={{ width: 120 }}
                >
                  Address
                </Th>
                <Th
                  sorted={sortBy === 'patientCity'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientCity')}
                  style={{ width: 100 }}
                >
                  City
                </Th>
                <Th
                  sorted={sortBy === 'liquid'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('liquid')}
                  style={{ width: 80 }}
                >
                  Liquid
                </Th>
                <Th
                  sorted={sortBy === 'isUrgent'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('isUrgent')}
                  style={{ width: 80 }}
                >
                  Urgent
                </Th>
                <Th
                  sorted={sortBy === 'standingOrderReschedule'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('standingOrderReschedule')}
                  style={{ width: 120 }}
                >
                  Rescheduled Standing Order
                </Th>
                <Th
                  sorted={sortBy === 'fulfilled'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('fulfilled')}
                  style={{ width: 120 }}
                >
                  Order Fulfilled
                </Th>
              </tr>
            </thead>
          </Table>
          {orders.data && <InfiniteScrollArea rows={rows} height={350} />}
          {!orders.data && orders.isLoading && (
            <Stack style={{ height: 350 }} align="center" justify="center">
              <Loader size={84} />
              <Text size="lg" weight={500}>
                Loading...
              </Text>
            </Stack>
          )}
        </ScrollArea>
        <Divider />
        <Group noWrap grow position="apart" mt="lg">
          <Button
            variant="outline"
            mb="lg"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <FilePlus style={{ marginRight: 5 }} />
            Add a New Order
          </Button>
        </Group>
      </Box>

      <OrderCreateModal
        opened={createModalOpen}
        selectedPatient={selectedPatient}
        onSelectPatient={() => {
          setCreateModalOpen(false);
          setPatientSelectModalOpen(true);
        }}
        onDeSelectPatient={() => {
          setSelectedPatient(undefined);
        }}
        onClose={() => {
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
        }}
        onCreate={(isCreated, newOrder) => {
          setOrderCreated(isCreated);
          setDetailOrder(newOrder);
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
          setDetailModalOpen(true);
        }}
      />
      <PatientSelectModal
        opened={patientSelectModalOpen}
        onClose={() => {
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
        onSelect={(isSelected, selectedPatient) => {
          setPatientSelected(isSelected);
          setSelectedPatient(selectedPatient);
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
      />
      <OrderDetailModal
        order={detailOrder}
        opened={detailModalOpen}
        isCreated={orderCreated}
        isUpdated={orderUpdated}
        isDeleted={orderDeleted}
        isError={orderUpdateError}
        onClose={() => {
          setOrderUpdated(false);
          setOrderUpdateError(false);
          setOrderCreated(false);
          setOrderDeleted(false);
          setDetailModalOpen(false);
        }}
        onEdit={() => {
          setEditModalOpen(true);
          setDetailModalOpen(false);
        }}
        onViewEquipment={() => {
          if (!detailOrder.fulfilled) {
            setEquipmentModalOpen(true);
          } else {
            setDeliveryEquipmentModalOpen(true);
          }
          setDetailModalOpen(false);
        }}
      />
      <OrderEditModal
        order={detailOrder}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isUpdated, isError, updatedOrder) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setOrderUpdated(isUpdated);
          setOrderUpdateError(isError);
          setDetailOrder(updatedOrder);
        }}
        onDeleted={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setOrderDeleted(true);
        }}
      />
      <OrderEquipmentModal
        order={detailOrder}
        opened={equipmentModalOpen}
        onClose={() => {
          setEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <DeliveryEquipmentModal
        recordId={detailOrder.fulfilledDeliveryId ?? '0'}
        recordType={'delivery'}
        opened={deliveryEquipmentModalOpen}
        onClose={() => {
          setDeliveryEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
    </Box>
  );
};

export default OnDemandOrdersSubPage;
