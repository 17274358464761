import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FilePlus, Search } from 'tabler-icons-react';
import {
  DialogModal,
  Th,
  TrackedEquipmentCreateModal,
  TrackedEquipmentDetailModal,
  TrackedEquipmentPatientHistoryModal,
  TrackedEquipmentServiceDetailModal,
  TrackedEquipmentServiceHistoryModal,
  TrackedEquipmentServiceCreateModal,
  TrackedPatientEquipmentDetailModal,
  TrackedEquipmentCleanHistoryModal,
  TrackedEquipmentCleanDetailModal,
  TrackedEquipmentCleanCreateModal,
  TrackedEquipmentRetireModal,
  TrackedEquipmentRepairHistoryModal,
  TrackedEquipmentRepairDetailModal,
  TrackedEquipmentRepairCreateModal,
  RepairLocationDisplayModal,
  TrackedEquipmentRepairCompleteModal,
  InfiniteScrollArea,
} from '../../components';
import {
  ITrackedEquipment,
  ITrackedEquipmentClean,
  ITrackedEquipmentRepair,
  ITrackedEquipmentService,
  ITrackedPatientEquipment,
} from '../../models';
import { useAllTrackedEquipment, useIsAdmin, useIsTechnician, usePageStore } from '../../hooks';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

function filterData(data: ITrackedEquipment[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: ITrackedEquipment[],
  payload: { sortBy: keyof ITrackedEquipment; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const TrackedEquipmentSubPage = (props: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  const trackedEquipment = useAllTrackedEquipment();

  const [equipmentCreated, setEquipmentCreated] = useState<boolean>(false);
  const [equipmentDeleted, setEquipmentDeleted] = useState<boolean>(false);
  const [equipmentRetired, setEquipmentRetired] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [patientHistoryModalOpen, setPatientHistoryModalOpen] = useState<boolean>(false);
  const [serviceHistoryModalOpen, setServiceHistoryModalOpen] = useState<boolean>(false);
  const [serviceDetailModalOpen, setServiceDetailModalOpen] = useState<boolean>(false);
  const [serviceCreateModalOpen, setServiceCreateModalOpen] = useState<boolean>(false);
  const [retireModalOpen, setRetireModalOpen] = useState<boolean>(false);
  const [detailService, setDetailService] = useState<ITrackedEquipmentService>({
    trackedEquipmentServiceId: '0',
    trackedEquipmentId: '',
    technicianUserId: '',
    technicianNameFormatted: '',
    serviceDateTime: new Date(),
    percentOxygen: 0,
    flowRate: 0,
    onHours: 0,
    offHours: 0,
    notes: '',
    readyForClean: false,
  });
  const [cleanHistoryModalOpen, setCleanHistoryModalOpen] = useState<boolean>(false);
  const [cleanDetailModalOpen, setCleanDetailModalOpen] = useState<boolean>(false);
  const [cleanCreateModalOpen, setCleanCreateModalOpen] = useState<boolean>(false);
  const [detailClean, setDetailClean] = useState<ITrackedEquipmentClean>({
    trackedEquipmentCleanId: '0',
    trackedEquipmentId: '',
    technicianUserId: '',
    technicianNameFormatted: '',
    cleanDateTime: new Date(),
    notes: '',
  });
  const [repairHistoryModalOpen, setRepairHistoryModalOpen] = useState<boolean>(false);
  const [repairDetailModalOpen, setRepairDetailModalOpen] = useState<boolean>(false);
  const [repairCreateModalOpen, setRepairCreateModalOpen] = useState<boolean>(false);
  const [detailRepair, setDetailRepair] = useState<ITrackedEquipmentRepair>({
    trackedEquipmentRepairId: '0',
    trackedEquipmentId: '',
    sentDateTime: new Date(),
    notes: '',
  });
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [detailEquipment, setDetailEquipment] = useState<ITrackedEquipment>({
    trackedEquipmentId: '0',
    equipmentId: '',
    equipmentName: '',
    equipmentTypeName: '',
    serialNumber: '',
    warrantyExpiryDate: new Date(),
    underWarranty: false,
    percentOxygen: 0,
    flowRate: 0,
    onHours: 0,
    offHours: 0,
    notes: '',
    retired: false,
    readyForClean: false,
    inNeedOfRepair: false,
    outForRepairs: false,
    ready: false,
  });
  const [patientEquipmentModalOpen, setPatientEquipmentModalOpen] = useState<boolean>(false);
  const [selectedTrackedPatientEquipment, setSelectedTrackedPatientEquipment] = useState<
    ITrackedPatientEquipment | undefined
  >();
  const [detailRepairLocationModalOpen, setDetailRepairLocationModalOpen] =
    useState<boolean>(false);
  const [createRepairLocationModalOpen, setCreateRepairLocationModalOpen] =
    useState<boolean>(false);
  const [repairCompleteModalOpen, setRepairCompleteModalOpen] = useState<boolean>(false);

  const [serviceCreated, setServiceCreated] = useState<boolean>(false);
  const [serviceDeleted, setServiceDeleted] = useState<boolean>(false);
  const [cleanCreated, setCleanCreated] = useState<boolean>(false);
  const [cleanDeleted, setCleanDeleted] = useState<boolean>(false);
  const [repairCreated, setRepairCreated] = useState<boolean>(false);
  const [repairDeleted, setRepairDeleted] = useState<boolean>(false);
  const [repairCompleted, setRepairCompleted] = useState<boolean>(false);

  const filterValue = usePageStore((state) => state.trackedEquipmentFilterValue);
  const setFilterValue = usePageStore((state) => state.setTrackedEquipmentFilterValue);

  const defaultKeys: string[] = [
    'equipmentName',
    'equipmentTypeName',
    'serialNumber',
    'ready',
    'underWarranty',
    'checkedOutDate',
    'patientName',
    'lastReturnedDate',
  ];

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(trackedEquipment.data);
  const [sortBy, setSortBy] = useState<keyof ITrackedEquipment>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof ITrackedEquipment) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(trackedEquipment.data!, { sortBy: field, reversed, search }, defaultKeys)
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        trackedEquipment.data!,
        { sortBy, reversed: reverseSortDirection, search: value },
        defaultKeys
      )
    );
  };

  const rows = sortedData?.map((row) => {
    let newRow = (
      <tr
        key={row.trackedEquipmentId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDetailEquipment(row);
          setDetailModalOpen(true);
        }}
      >
        <td style={{ width: 140, wordWrap: 'break-word' }}>{`${row.equipmentName}`}</td>
        <td style={{ width: 140, wordWrap: 'break-word' }}>{`${row.equipmentTypeName}`}</td>
        <td style={{ width: 140, wordWrap: 'break-word' }}>{`${row.serialNumber}`}</td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          <Checkbox checked={row.readyForClean && !row.retired} readOnly />
        </td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          <Checkbox checked={row.inNeedOfRepair && !row.retired} readOnly />
        </td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          <Checkbox checked={row.ready && !row.retired} readOnly />
        </td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          <Checkbox checked={row.underWarranty} readOnly />
        </td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          {row.checkedOutDate ? dayjs(row.checkedOutDate).format('YYYY-MM-DD') : '-'}
        </td>
        {!row.outForRepairs && (
          <td style={{ width: 140, wordWrap: 'break-word' }}>
            {row.checkedOutDate ? row.patientName ?? '-' : '-'}
          </td>
        )}
        {row.outForRepairs && (
          <td style={{ width: 140, wordWrap: 'break-word' }}>
            <i>Out For Repair</i>
          </td>
        )}
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          {row.lastReturnedDate && !row.checkedOutDate
            ? dayjs(row.lastReturnedDate).format('YYYY-MM-DD')
            : '-'}
        </td>
      </tr>
    );

    if (filterValue === 'all' && !row.retired) return newRow;
    if (filterValue === 'inStock' && !row.checkedOutDate) return newRow;
    if (filterValue === 'readyForClean' && row.readyForClean) return newRow;
    if (filterValue === 'inNeedOfRepair' && row.inNeedOfRepair) return newRow;
    if (filterValue === 'outForRepair' && row.outForRepairs) return newRow;
    if (filterValue === 'ready' && row.ready && !row.retired) return newRow;
    if (filterValue === 'unready' && !row.ready && !row.checkedOutDate) return newRow;
    if (filterValue === 'checkedOut' && row.checkedOutDate) return newRow;
    if (filterValue === 'retired' && row.retired) return newRow;
  });

  useEffect(() => {
    if (!trackedEquipment.data) {
      setSortedData(trackedEquipment.data);
    } else {
      setSortedData(
        sortData(
          trackedEquipment.data,
          { sortBy, reversed: reverseSortDirection, search },
          defaultKeys
        )
      );
    }
  }, [trackedEquipment.data, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    if (!serviceHistoryModalOpen) {
      setServiceCreated(false);
      setServiceDeleted(false);
    }
  }, [serviceHistoryModalOpen]);

  useEffect(() => {
    if (!cleanHistoryModalOpen) {
      setCleanCreated(false);
      setCleanDeleted(false);
    }
  }, [cleanHistoryModalOpen]);

  useEffect(() => {
    if (!repairHistoryModalOpen) {
      setRepairCreated(false);
      setRepairDeleted(false);
      setRepairCompleted(false);
    }
  }, [repairHistoryModalOpen]);

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ height: '100%' }}>
        <Center mb="sm">
          <Group
            position="apart"
            p="sm"
            className={classes.card}
            mb={`calc(${theme.spacing.md} - 1)`}
          >
            <Text style={{ width: 'fit-content' }}>Show Equipment:</Text>
            <Center>
              <Chip.Group
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.toString());
                }}
              >
                <Stack spacing={5}>
                  <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                    <Chip variant="filled" value="all">
                      All Active
                    </Chip>
                    <Chip variant="filled" value="inStock">
                      In Stock
                    </Chip>
                    <Chip variant="filled" value="readyForClean">
                      Ready for Cleaning
                    </Chip>
                    <Chip variant="filled" value="inNeedOfRepair">
                      In Need of Repairs
                    </Chip>
                    <Chip variant="filled" value="outForRepair">
                      Out for Repair
                    </Chip>
                  </Group>
                  <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                    <Chip variant="filled" value="ready">
                      Ready for Delivery
                    </Chip>
                    <Chip variant="filled" value="unready">
                      Unready
                    </Chip>
                    <Chip variant="filled" value="checkedOut">
                      Checked Out
                    </Chip>
                    <Chip variant="filled" value="retired">
                      Retired
                    </Chip>
                  </Group>
                </Stack>
              </Chip.Group>
            </Center>
          </Group>
        </Center>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <ScrollArea type="hover" style={{ width: '100%' }}>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <Th
                  sorted={sortBy === 'equipmentName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('equipmentName')}
                  style={{ width: 140 }}
                >
                  Equipment Name
                </Th>
                <Th
                  sorted={sortBy === 'equipmentTypeName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('equipmentTypeName')}
                  style={{ width: 140 }}
                >
                  Category
                </Th>
                <Th
                  sorted={sortBy === 'serialNumber'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('serialNumber')}
                  style={{ width: 140 }}
                >
                  Serial Number
                </Th>
                <Th
                  sorted={sortBy === 'readyForClean'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('readyForClean')}
                  style={{ width: 100 }}
                >
                  Ready for Cleaning
                </Th>
                <Th
                  sorted={sortBy === 'readyForClean'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('readyForClean')}
                  style={{ width: 100 }}
                >
                  Needs Repairs
                </Th>
                <Th
                  sorted={sortBy === 'ready'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('ready')}
                  style={{ width: 100 }}
                >
                  Ready for Delivery
                </Th>
                <Th
                  sorted={sortBy === 'underWarranty'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('underWarranty')}
                  style={{ width: 100 }}
                >
                  Under Warranty
                </Th>
                <Th
                  sorted={sortBy === 'checkedOutDate'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('checkedOutDate')}
                  style={{ width: 120 }}
                >
                  Checked Out
                </Th>
                <Th
                  sorted={sortBy === 'patientName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientName')}
                  style={{ width: 140 }}
                >
                  Patient Name
                </Th>
                <Th
                  sorted={sortBy === 'lastReturnedDate'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('lastReturnedDate')}
                  style={{ width: 120 }}
                >
                  Returned
                </Th>
              </tr>
            </thead>
          </Table>
          {trackedEquipment.data && <InfiniteScrollArea rows={rows} height={310} />}
          {!trackedEquipment.data && trackedEquipment.isLoading && (
            <Stack style={{ height: '350px' }} align="center" justify="center">
              <Loader size={84} />
              <Text size="lg" weight={500}>
                Loading...
              </Text>
            </Stack>
          )}
        </ScrollArea>
        <Divider />
        <Group noWrap grow position="apart" mt="lg">
          <Button
            variant="outline"
            mb="lg"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <FilePlus style={{ marginRight: 5 }} />
            Add New Tracked Equipment
          </Button>
        </Group>
      </Box>
      <TrackedEquipmentDetailModal
        opened={detailModalOpen}
        isCreated={equipmentCreated}
        isRetired={equipmentRetired}
        trackedEquipment={detailEquipment}
        onViewPatientHistory={() => {
          setDetailModalOpen(false);
          setPatientHistoryModalOpen(true);
        }}
        onDelete={() => {
          setDetailModalOpen(false);
        }}
        onRetire={() => {
          setDetailModalOpen(false);
          setRetireModalOpen(true);
        }}
        onUnretired={() => {
          setDetailEquipment((a) => ({ ...a, retired: false }));
        }}
        onServicing={() => {
          setDetailModalOpen(false);
          setServiceHistoryModalOpen(true);
        }}
        onCleaning={() => {
          setDetailModalOpen(false);
          setCleanHistoryModalOpen(true);
        }}
        onRepair={() => {
          setDetailModalOpen(false);
          setRepairHistoryModalOpen(true);
        }}
        onClose={() => {
          setDetailModalOpen(false);
          setEquipmentCreated(false);
          setEquipmentRetired(false);
        }}
      />
      <TrackedEquipmentCreateModal
        opened={createModalOpen}
        onCreate={(isCreated, newTrackedEquipment) => {
          setEquipmentCreated(isCreated);
          setDetailEquipment(newTrackedEquipment);
          setCreateModalOpen(false);
          setDetailModalOpen(true);
        }}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
      <TrackedEquipmentPatientHistoryModal
        opened={patientHistoryModalOpen}
        trackedEquipment={detailEquipment}
        onSelect={(trackedPatientEquipment) => {
          setSelectedTrackedPatientEquipment(trackedPatientEquipment);
          setPatientHistoryModalOpen(false);
          setPatientEquipmentModalOpen(true);
        }}
        onClose={() => {
          setPatientHistoryModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <TrackedPatientEquipmentDetailModal
        opened={patientEquipmentModalOpen}
        trackedEquipment={detailEquipment}
        trackedPatientEquipment={selectedTrackedPatientEquipment}
        onClose={() => {
          setPatientEquipmentModalOpen(false);
          setPatientHistoryModalOpen(true);
        }}
      />
      <TrackedEquipmentServiceHistoryModal
        trackedEquipment={detailEquipment}
        serviceCreated={serviceCreated}
        serviceDeleted={serviceDeleted}
        opened={serviceHistoryModalOpen}
        onSelect={(service) => {
          setDetailService(service);
          setServiceHistoryModalOpen(false);
          setServiceDetailModalOpen(true);
        }}
        onService={() => {
          setServiceHistoryModalOpen(false);
          setServiceCreateModalOpen(true);
        }}
        onClose={() => {
          setServiceHistoryModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <TrackedEquipmentServiceDetailModal
        trackedEquipment={detailEquipment}
        service={detailService}
        opened={serviceDetailModalOpen}
        onClose={() => {
          setServiceDetailModalOpen(false);
          setServiceHistoryModalOpen(true);
        }}
        onDelete={() => {
          setServiceDeleted(true);
          setServiceDetailModalOpen(false);
          setServiceHistoryModalOpen(true);
        }}
      />
      {(isAdmin || isTechnician) && (
        <TrackedEquipmentServiceCreateModal
          trackedEquipment={detailEquipment}
          opened={serviceCreateModalOpen}
          onClose={() => {
            setServiceCreateModalOpen(false);
            setServiceHistoryModalOpen(true);
          }}
          onCreate={(percentOxygen, flowRate, onHours, offHours, readyForClean) => {
            setServiceCreated(true);
            setDetailEquipment((a) => ({
              ...a,
              percentOxygen: percentOxygen,
              flowRate: flowRate,
              onHours: onHours,
              offHours: offHours,
              readyForClean: readyForClean,
            }));
            setServiceCreateModalOpen(false);
            setServiceHistoryModalOpen(true);
          }}
        />
      )}
      <TrackedEquipmentCleanHistoryModal
        trackedEquipment={detailEquipment}
        cleanCreated={cleanCreated}
        cleanDeleted={cleanDeleted}
        opened={cleanHistoryModalOpen}
        onSelect={(clean) => {
          setDetailClean(clean);
          setCleanHistoryModalOpen(false);
          setCleanDetailModalOpen(true);
        }}
        onClean={() => {
          setCleanHistoryModalOpen(false);
          setCleanCreateModalOpen(true);
        }}
        onClose={() => {
          setCleanHistoryModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <TrackedEquipmentCleanDetailModal
        trackedEquipment={detailEquipment}
        clean={detailClean}
        opened={cleanDetailModalOpen}
        onClose={() => {
          setCleanDetailModalOpen(false);
          setCleanHistoryModalOpen(true);
        }}
        onDelete={() => {
          setCleanDeleted(true);
          setCleanDetailModalOpen(false);
          setCleanHistoryModalOpen(true);
        }}
      />
      {(isAdmin || isTechnician) && detailEquipment.readyForClean && (
        <TrackedEquipmentCleanCreateModal
          trackedEquipment={detailEquipment}
          opened={cleanCreateModalOpen}
          onClose={() => {
            setCleanCreateModalOpen(false);
            setCleanHistoryModalOpen(true);
          }}
          onCreate={() => {
            setCleanCreated(true);
            setCleanCreateModalOpen(false);
            setCleanHistoryModalOpen(true);
          }}
        />
      )}
      <TrackedEquipmentRepairHistoryModal
        trackedEquipment={detailEquipment}
        repairCreated={repairCreated}
        repairDeleted={repairDeleted}
        opened={repairHistoryModalOpen}
        onSelect={(repair) => {
          setDetailRepair(repair);
          setRepairHistoryModalOpen(false);
          setRepairDetailModalOpen(true);
        }}
        onRepair={() => {
          setRepairHistoryModalOpen(false);
          setRepairCreateModalOpen(true);
        }}
        onClose={() => {
          setRepairHistoryModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <TrackedEquipmentRepairDetailModal
        trackedEquipment={detailEquipment}
        repair={detailRepair}
        opened={repairDetailModalOpen}
        onClose={() => {
          setRepairDetailModalOpen(false);
          setRepairHistoryModalOpen(true);
        }}
        onComplete={() => {
          setRepairDetailModalOpen(false);
          setRepairCompleteModalOpen(true);
        }}
        onDelete={() => {
          setRepairDeleted(true);
          setRepairDetailModalOpen(false);
          setRepairHistoryModalOpen(true);
        }}
        onAddRepairLocation={() => {
          setRepairDetailModalOpen(false);
          setDetailRepairLocationModalOpen(true);
        }}
      />
      {(isAdmin || isTechnician) && (
        <TrackedEquipmentRepairCreateModal
          trackedEquipment={detailEquipment}
          opened={repairCreateModalOpen}
          onClose={() => {
            setRepairCreateModalOpen(false);
            setRepairHistoryModalOpen(true);
          }}
          onCreate={() => {
            setRepairCreated(true);
            setDetailEquipment((a) => ({ ...a, outForRepairs: true, readyForClean: false }));
            setRepairCreateModalOpen(false);
            setRepairHistoryModalOpen(true);
          }}
          onAddRepairLocation={() => {
            setRepairCreateModalOpen(false);
            setCreateRepairLocationModalOpen(true);
          }}
        />
      )}
      {(isAdmin || isTechnician) && (
        <RepairLocationDisplayModal
          opened={createRepairLocationModalOpen || detailRepairLocationModalOpen}
          onClose={() => {
            if (createRepairLocationModalOpen) {
              setCreateRepairLocationModalOpen(false);
              setRepairCreateModalOpen(true);
            } else {
              setDetailRepairLocationModalOpen(false);
              setRepairDetailModalOpen(true);
            }
          }}
        />
      )}
      <TrackedEquipmentRepairCompleteModal
        opened={repairCompleteModalOpen}
        trackedEquipment={detailEquipment}
        repair={detailRepair}
        onComplete={(returnedDateTime) => {
          setDetailEquipment((a) => ({
            ...a,
            returnedDateTime: returnedDateTime,
            outForRepairs: false,
            readyForClean: false,
          }));
          setDetailRepair((a) => ({
            ...a,
            returnedDateTime: returnedDateTime,
          }));
          setRepairCompleted(true);
          setRepairCompleteModalOpen(false);
          setRepairDetailModalOpen(true);
        }}
        onClose={() => {
          setRepairCompleteModalOpen(false);
          setRepairDetailModalOpen(true);
        }}
      />
      <TrackedEquipmentRetireModal
        opened={retireModalOpen}
        trackedEquipment={detailEquipment}
        onRetire={(notes) => {
          setDetailEquipment((a) => ({ ...a, retired: true, retiredNotes: notes }));
          setEquipmentRetired(true);
          setRetireModalOpen(false);
          setDetailModalOpen(true);
        }}
        onClose={() => {
          setRetireModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <DialogModal
        isOpen={equipmentDeleted}
        titleText={'Equipment Deleted'}
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Equipment Successfully Deleted!
          </Text>
        }
        confirmText={'OK'}
        singleButton
        onConfirm={() => {
          setEquipmentDeleted(false);
        }}
        onClose={() => {
          setEquipmentDeleted(false);
        }}
      />
    </Box>
  );
};

export default TrackedEquipmentSubPage;
