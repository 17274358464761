import { Box, Loader, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

interface InfiniteScrollAreaProps {
  rows: (JSX.Element | undefined)[] | undefined;
  height?: number;
}

const InfiniteScrollArea = (props: InfiniteScrollAreaProps) => {
  const heightRef = useRef<HTMLTableElement | null>(null);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

  const itemsPerPage = 50;
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [records, setRecords] = useState<number>(itemsPerPage);

  const showItems = (rows: (JSX.Element | undefined)[] | undefined) => {
    var items = [];
    if (rows) {
      for (var i = 0; i < records; i++) {
        items.push(rows[i]);
      }
    }
    return items;
  };

  const loadMore = () => {
    if (props.rows) {
      if (records >= props.rows.length) {
        setHasMore(false);
      } else {
        setHasMore(false);
        setTimeout(() => {
          setRecords(records + itemsPerPage);
        });
      }
    }
  };

  useEffect(() => {
    if (scrollPosition.y >= heightRef.current!.clientHeight / 2) {
      setHasMore(true);
    }
  }, [scrollPosition]);

  return (
    <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore} useWindow={false}>
      <ScrollArea
        type="hover"
        style={{ height: props.height ?? 300, tableLayout: 'fixed' }}
        onScrollPositionChange={onScrollPositionChange}
      >
        <Table
          highlightOnHover
          horizontalSpacing="md"
          verticalSpacing="xs"
          style={{ tableLayout: 'fixed', width: '100%' }}
          ref={heightRef}
        >
          <tbody>
            {showItems(props.rows)}
            {(!props.rows || props.rows.every((a) => a === undefined)) && (
              <tr>
                <td colSpan={0}>
                  <Text weight={500} align="center">
                    Nothing found
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </InfiniteScroll>
  );
};

export default InfiniteScrollArea;
