import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IOrder } from '../../models';

function fetchOrders(): Promise<IOrder[]> {
  return externalApi.get(`Order/all`).json();
}

export function useOrders() {
  return useQuery(['Orders'], () => fetchOrders());
}
