import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipment } from '../../models';

function fetchAllTrackedEquipment(): Promise<ITrackedEquipment[]> {
  return externalApi.get(`TrackedEquipment/all`).json();
}

export function useAllTrackedEquipment() {
  return useQuery(['AllTrackedEquipment'], () => fetchAllTrackedEquipment());
}
